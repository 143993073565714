<template>
  <div>
    <div class="content">
      <div class="contentBox">
        <div class="serchBox">
          <div class="logoImg">
            <img src="../../assets/img/logo-p.png" alt="" srcset="" />
          </div>
          <div class="inpBox">
            <div class="inp">
              <div class="search">
                <div class="iconfont icon-search"></div>
              </div>
              <div class="shu"></div>
              <input
                type="text"
                placeholder="请输入机器名称"
                v-model="searChTxt"
                @keyup.enter="searchClick"
                name=""
                id=""
              />
              <div class="searchBtn" @click="searchClick">搜索</div>
            </div>
          </div>
        </div>
        <div class="nrContent">
          <div class="nrLeftBox">
            <div class="chengzhihao">
              <div class="text">所有产品</div>
            </div>
            <div class="pzBox" :class="shang == 'shang' ? 'pzBoxh' : 0">
              <!-- <div class="modoBox" @click="sxClick">
                <div class="name">展示筛选</div>
                <div class="iconfont icon-unfold" :class="shang"></div>
              </div> -->
              <div class="pzCnt">
                <!-- <div class="name">规格:</div> -->
                <div class="boxs">
                  <div
                    class="box"
                    v-for="item in lxTypeList"
                    :class="item.id == lxTypeOn ? 'on' : ''"
                    @click="lxTypeClick(item.id)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <!-- <div class="pzCnt">
                <div class="name">规格:</div>
                <div class="boxs">
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                </div>
              </div>
              <div class="pzCnt">
                <div class="name">规格:</div>
                <div class="boxs">
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                  <div class="box">参数1</div>
                </div>
              </div> -->
            </div>
            <div class="sxCont">
              <div
                class="text"
                :class="index == typeOn ? 'on' : ''"
                v-for="(item, index) in typeList"
                @click="typeClick(index)"
              >
                {{ item }}
              </div>
              <div class="jgBox" @mouseenter="mouseover" @mouseleave="mouseout">
                <div class="name" :class="priceTypeOn ? 'on' : ''">价格</div>
                <div
                  class="iconfont icon-unfold"
                  :class="priceTypeOn ? 'on' : ''"
                ></div>
              </div>
              <div
                class="kBox"
                v-if="jgShow"
                @mouseenter="mouseover"
                @mouseleave="mouseout"
              >
                <div
                  class="name"
                  :class="index == priceTypeListOn ? 'on' : ''"
                  v-for="(item, index) in priceType"
                  @click="priceTypeClick(index, item)"
                >
                  {{ item.name }}
                </div>
              </div>
              <div class="inpBox">
                <div class="inp">
                  <input
                    type="text"
                    placeholder="￥"
                    name=""
                    id=""
                    v-model="minprice"
                  />
                </div>
                <div class="hen"></div>
                <div class="inp">
                  <input
                    type="text"
                    placeholder="￥"
                    name=""
                    id=""
                    v-model="maxprice"
                  />
                </div>
                <div class="serveBtn" @click="priceBtn">确定</div>
              </div>
            </div>
            <div class="contList">
              <Kong v-if="list.length == 0" />
              <div class="cont" v-for="item in list" @click="cpJump(item.id)">
                <div class="cpImg">
                  <img :src="$imgUrl + item.resources[0].path_name" alt="" />
                </div>
                <div class="title c-1">{{ item.name }}</div>
                <div class="money">
                  <div class="txt">￥</div>
                  <div class="num">{{ item.minprice }}</div>
                  <div class="text">{{ item.num }}+人购买</div>
                </div>
              </div>
            </div>
            <div class="fenye" v-if="totalCount > 20">
              <a-pagination
                :default-current="page.pageNo"
                  v-model="page.pageNo"
                :total="totalCount"
                :page-size="20"
                @change="pageOnChange"
              />
            </div>
          </div>
          <div class="nrRightBox">
            <div class="chengzhihao">
              <div class="text">产品推荐</div>
            </div>
            <div class="contList">
              <div class="cont" v-for="item in tjList" @click="cpJump(item.id)">
                <div class="cpImg">
                  <img :src="$imgUrl + item.resources[0].path_name" alt="" />
                </div>
                <div class="title c-1">{{ item.name }}</div>
                <div class="money">
                  <div class="txt">￥</div>
                  <div class="num">{{ item.minprice }}</div>
                  <div class="text">{{ item.num }}+人购买</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Kong from "../component/kong.vue";

export default {
  name: "",
  components: {
    Kong,
  },
  data() {
    return {
      shang: "",
      jgShow: false,
      typeOn: 0,
      typeList: ["综合", "销量"],
      searChTxt: "",
      judge: true,
      page: {
        pageNo: 1,
        pageSize: 20,
      },
      list: [],
      tjList: [],
      minprice: "",
      maxprice: "",
      priceTypeOn: false,
      priceTypeListOn: null,
      priceType: [
        {
          name: "价格从高到低",
          odb: "b.minprice desc",
        },
        {
          name: "价格从低到高",
          odb: "b.minprice asc",
        },
      ],
      lxTypeOn: "",
      lxTypeList: [],
      totalCount: 0,
    };
  },
  created() {
    if (this.$route.query.name) {
      this.searChTxt = this.$route.query.name;
      this.page.name = this.$route.query.name;
    }
    this.listGet();
    this.tjListGet();
    this.lxTypeGet();
  },
  methods: {
    pageOnChange(i) {
      this.list = [];
      this.page.pageNo = i;
      this.listGet();
    },
    lxTypeClick(id) {
      this.lxTypeOn = id;
      this.page.config_id = id;
      this.page.pageNo = 1;
      this.list = [];
      this.totalCount = 0
      this.listGet();
    },
    lxTypeGet() {
      this.$get("/common/configuration_gets", {
        type: "part",
      }).then((res) => {
        this.lxTypeList = res.data;
        this.lxTypeList.unshift({
          id: "",
          resources: null,
          name: "全部类型",
        });
      });
    },
    priceTypeClick(i, item) {
      this.page.pageNo = 1;
      this.priceTypeListOn = i;
      this.page.odb = item.odb;
      this.priceTypeOn = true;
      this.typeOn = null;
      this.list = [];
      this.totalCount = 0
      this.listGet();
    },
    typeClick(i) {
      this.typeOn = i;
      this.page.pageNo = 1;
      this.list = [];
      if (i == 1) {
        this.page.odb = "a.sales desc";
      } else if (i == 0) {
        this.page.odb = "";
      }
      this.priceTypeListOn = null;
      this.priceTypeOn = false;
      this.totalCount = 0
      this.listGet();
    },
    listGet() {
      if (!this.judge) {
        return;
      }
      this.judge = false;
      this.$get("/web/part_gets", this.page).then((res) => {
        console.log(res);
        this.judge = true;
        if (!this.totalCount) {
          this.totalCount = res.totalCount;
        }
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            e.num = this.roundToNearestTen(e.sales);
            this.list.push(e);
          }
        }
      });
    },
    tjListGet() {
      this.$get("/web/part_gets", {
        recommend: 1,
      }).then((res) => {
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            e.num = this.roundToNearestTen(e.sales);
            this.tjList.push(e);
          }
        }
      });
    },
    roundToNearestTen(num) {
      if (num <= 10) {
        return 10;
      } else {
        // 获取数字的整数部分
        let intPart = Math.floor(Math.abs(num));
        // 判断个位数是否大于或等于5，决定是向上还是向下舍入
        if (intPart % 10 >= 5) {
          // 如果大于或等于5，则向上舍入
          intPart += 10 - (intPart % 10);
        } else {
          // 如果小于5，则直接向下舍入整十
          intPart -= intPart % 10;
        }
        // 处理负数和保留原始符号
        return (num < 0 ? -intPart : intPart) * Math.sign(num);
      }
    },
    //获取url参数
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      console.log(query);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    },
    mouseover() {
      this.jgShow = true;
    },
    mouseout() {
      this.jgShow = false;
    },
    sxClick() {
      if (!this.shang) {
        this.shang = "shang";
      } else {
        if (this.shang == "shang") {
          this.shang = "xia";
        } else {
          this.shang = "shang";
        }
      }
    },
    priceBtn() {
      if (!this.minprice) {
        this.$message.error("请输入数字");
        return;
      }
      if (!this.maxprice) {
        this.$message.error("请输入数字");
        return;
      }
      if (isNaN(this.minprice)) {
        this.$message.error("请输入数字");
        return;
      }
      if (isNaN(this.maxprice)) {
        this.$message.error("请输入数字");
        return;
      }
      if (this.minprice >= this.maxprice) {
        this.$message.error("最小值小于最大值");
        return;
      }
      this.page.minprice = this.minprice;
      this.page.maxprice = this.maxprice;
      this.page.pageNo = 1;
      this.list = [];
      this.totalCount = 0
      this.listGet();
    },
    searchClick() {
      this.page.name = this.searChTxt;
      this.page.pageNo = 1;
      this.list = [];
      this.totalCount = 0
      this.listGet();
    },
    mouseCxover(i) {
      this.cxList[i].select = true;
    },
    mouseCxout(i) {
      this.cxList[i].select = false;
    },
    onChange(a) {
      // console.log(a);
    },
    cpJump(id) {
      this.$router.push({
        path: "/shopCont",
        query: {
          id,
        },
      });
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  .contentBox {
    width: 1440px;
    margin: auto;
    box-sizing: border-box;
    .serchBox {
      display: flex;
      padding: 30px 70px;
      align-items: center;
      .logoImg {
        width: 210px;
      }
      .inpBox {
        width: calc(100% - 250px);
        box-sizing: border-box;
        padding-left: 50px;
        .inp {
          border: 2px solid #f66;
          background-color: #fff;
          width: 100%;
          height: 50px;
          border-radius: 10px;
          display: flex;
          overflow: hidden;
          align-items: center;
          position: relative;
          .search {
            width: 65px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            .iconfont {
              font-size: 24px;
              color: #999;
            }
          }
          .shu {
            width: 2px;
            height: 20px;
            background-color: #c9c9c9;
          }
          input {
            width: calc(100% - 67px);
            height: 100%;
            padding-left: 20px;
            box-sizing: border-box;
          }
          .searchBtn {
            cursor: pointer;
            width: 90px;
            height: calc(100% - 4px);
            position: absolute;
            right: 2px;
            top: 2px;
            background-color: rgb(233, 7, 7);
            color: #fff;
            font-weight: 600;
            font-size: 16px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    .nrContent {
      border-radius: 10px;
      background-color: #fff;
      padding: 20px 20px;
      display: flex;
      .nrLeftBox {
        width: calc(100% - 250px);
        .chengzhihao {
          padding-bottom: 20px;
          border-bottom: 1px solid #ebebeb;
          .text {
            font-weight: 600;
            font-size: 20px;
          }
        }
        .pzBox {
          padding-top: 20px;
          border-bottom: 1px solid #ebebeb;
          height: 61px;
          overflow: hidden;
          position: relative;
          .modoBox {
            position: absolute;
            top: 16px;
            right: 0;
            display: flex;
            background-color: whitesmoke;
            padding: 5px 5px 5px 10px;
            border-radius: 5px;
            cursor: pointer;
            .name {
              font-size: 12px;
            }
            .iconfont {
              font-size: 12px;
            }
            .shang {
              animation: shang 0.5s both;
            }
            @keyframes shang {
              0% {
                transform: rotate(0deg);
              }
              100% {
                transform: rotate(-180deg);
              }
            }
            .xia {
              animation: xia 0.5s both;
            }
            @keyframes xia {
              0% {
                transform: rotate(-180deg);
              }
              100% {
                transform: rotate(0deg);
              }
            }
          }
          .pzCnt {
            display: flex;
            .name {
              font-weight: 600;
              width: 120px;
            }
            .boxs {
              width: calc(100% - 120px);
              overflow: hidden;
              .box {
                float: left;
                margin-right: 25px;
                margin-bottom: 15px;
                color: #666;
                cursor: pointer;
              }
              .on {
                color: #0976f3;
              }
            }
          }
          .pzCnt:nth-child(2n) {
            width: calc(100% - 100px);
          }
        }
        .pzBoxh {
          height: auto;
        }
        .sxCont {
          padding-top: 20px;
          display: flex;
          align-items: center;
          font-size: 16px;
          position: relative;
          .text {
            margin-right: 25px;
            cursor: pointer;
          }
          .text:hover {
            color: #f66;
          }
          .jgBox {
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 30px;
            margin-right: 25px;
          }
          .on {
            color: #f66;
          }
          .kBox {
            position: absolute;
            top: 50px;
            left: 90px;
            box-shadow: 0 0 4px 2px #ebebeb;
            border-radius: 5px;
            font-size: 14px;
            background-color: #fff;
            z-index: 99;
            .name {
              padding: 10px;
              border-bottom: 1px solid #ebebeb;
              cursor: pointer;
            }
            .name:hover {
              color: #f66;
            }
            .name:last-child {
              border-bottom: none;
            }
          }
          .inpBox {
            display: flex;
            align-items: center;
            .inp {
              width: 80px;
              border-radius: 5px;
              border: 1px solid #cfcfcf;
              height: 35px;
              overflow: hidden;
              box-sizing: border-box;
              input {
                width: 100%;
                height: 100%;
                padding-left: 8px;
                box-sizing: border-box;
                font-size: 14px;
              }
            }
            .hen {
              width: 5px;
              height: 1px;
              background-color: #9c9c9c;
              margin: 0 5px;
            }
            .serveBtn {
              font-size: 14px;
              margin-left: 10px;
              width: 60px;
              border-radius: 5px;
              height: 35px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgb(30, 135, 233);
              color: #fff;
              cursor: pointer;
            }
          }
        }
        .contList {
          margin-top: 25px;
          overflow: hidden;
          .cont {
            cursor: pointer;
            width: calc(20% - 12px);
            margin-right: 15px;
            background-color: #fff;
            border-radius: 10px;
            overflow: hidden;
            float: left;
            height: 369px;
            margin-bottom: 5px;
            position: relative;
            .bjImg {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              img {
                object-fit: cover;
              }
            }
            .bjBox {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              .contTxt {
                height: 50px;
                margin-top: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                .title1 {
                  padding: 10px 25px;
                  border-radius: 30px;
                  color: #fff;
                  background-color: rgb(240, 12, 12);
                  font-size: 16px;
                  font-weight: 600;
                }
              }
              .bjList {
                padding: 0 25px;
                margin-top: 20px;
                .bjLi {
                  border: 1px solid rgb(204, 58, 5);
                  color: rgb(226, 20, 13);
                  border-radius: 10px;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  width: calc(50% - 5px);
                  float: left;
                  height: 35px;
                  line-height: 35px;
                  text-align: center;
                }
                .bjLi:nth-child(2n) {
                  margin-right: 0;
                }
              }
            }
            .cpImg {
              height: 280px;
              border-radius: 10px;
              overflow: hidden;
              img {
                object-fit: cover;
                // width: 100%;
                // height: auto;
              }
            }
            .title {
              height: 40px;
              box-sizing: border-box;
              padding: 10px;
              font-size: 16px;
              padding-bottom: 0;
            }
            .money {
              display: flex;
              padding: 0 10px;
              padding-bottom: 10px;
              align-items: flex-end;
              .txt {
                margin-bottom: 5px;
                color: #f40;
              }
              .num {
                font-weight: 600;
                font-size: 26px;
                color: #f40;
              }
              .text {
                margin-bottom: 5px;
                margin-left: 10px;
                color: #9c9c9c;
              }
            }
          }
          .cont:nth-child(5n) {
            margin-right: 0;
          }
        }
      }
      .nrRightBox {
        width: 250px;
        box-sizing: border-box;
        padding-left: 25px;
        .chengzhihao {
          padding-bottom: 20px;
          .text {
            font-weight: 600;
            font-size: 20px;
          }
        }
        .contList {
          overflow: hidden;
          .cont {
            cursor: pointer;
            width: 100%;
            margin-right: 15px;
            background-color: #fff;
            border-radius: 10px;
            overflow: hidden;
            float: left;
            height: 299px;
            margin-bottom: 0;
            position: relative;
            .bjImg {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              img {
                object-fit: cover;
              }
            }
            .bjBox {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              .contTxt {
                height: 50px;
                margin-top: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                .title1 {
                  padding: 10px 25px;
                  border-radius: 30px;
                  color: #fff;
                  background-color: rgb(240, 12, 12);
                  font-size: 16px;
                  font-weight: 600;
                }
              }
              .bjList {
                padding: 0 25px;
                margin-top: 20px;
                .bjLi {
                  border: 1px solid rgb(204, 58, 5);
                  color: rgb(226, 20, 13);
                  border-radius: 10px;
                  margin-right: 10px;
                  margin-bottom: 10px;
                  width: calc(50% - 5px);
                  float: left;
                  height: 35px;
                  line-height: 35px;
                  text-align: center;
                }
                .bjLi:nth-child(2n) {
                  margin-right: 0;
                }
              }
            }
            .cpImg {
              height: 210px;
              border-radius: 10px;
              overflow: hidden;
              img {
                object-fit: cover;
              }
            }
            .title {
              height: 40px;
              box-sizing: border-box;
              padding: 10px;
              font-size: 16px;
              padding-bottom: 0;
            }
            .money {
              display: flex;
              padding: 0 10px;
              padding-bottom: 10px;
              align-items: flex-end;
              .txt {
                margin-bottom: 5px;
                color: #f40;
              }
              .num {
                font-weight: 600;
                font-size: 26px;
                color: #f40;
              }
              .text {
                margin-bottom: 5px;
                margin-left: 10px;
                color: #9c9c9c;
              }
            }
          }
          .cont:nth-child(5n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
</style>
